import { styled } from '@mui/material/styles';
import { RHFTextField } from './hook-form';
import RHFSelect from './hook-form/RHFSelect';
import { TextFieldProps } from '@mui/material';
import { IMaskInput } from 'react-imask';
import { forwardRef } from 'react';
import { Intl } from '../enum';

export const PhoneField = styled(RHFTextField)<TextFieldProps>((props: any) => ({
  '& .MuiOutlinedInput-root': {
    paddingLeft: 0,
  },
}));
export const PhoneCountryField = styled(RHFSelect)<TextFieldProps>((props: any) => ({
  '& .MuiOutlinedInput-root': {
    paddingLeft: 0,
    '&.Mui-focused fieldset': {
      border: `2px solid ${props.theme.palette.primary.main} !important`,
    },
  },
  '& fieldset': {
    border: 'none',
  },
  '& select': {
    paddingRight: '0px !important',
  },
}));
export const CountryField = styled(RHFSelect)<TextFieldProps>((props: any) => ({
  '& .MuiOutlinedInput-root': {
    paddingLeft: 0,
    '&.Mui-focused fieldset': {
      border: `2px solid ${props.theme.palette.primary.main} !important`,
    },
  },
  // '& fieldset': {
  //   border: 'none',
  // },
}));

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
//
export const phoneCustom = forwardRef<HTMLElement, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00 00 00 00 00 00 00 00"
      definitions={{
        '': /[1-9]/,
      }}
      // @ts-ignore
      inputRef={ref}
      onAccept={(value: any) => {
        onChange(value);
        // return onChange({ target: { name: props.name, value } });
      }}
      overwrite
    />
  );
});

export const supportedLeadFormLanguages = [Intl.DA, Intl.EN, Intl.NO, Intl.NN, Intl.DE];

// NOT ALL LANGUAGES SUPPORTED
export const countries = [
  {
    languageCode: Intl.DA,
    code: 'DK',
    label: 'Danmark',
    language: 'Dansk',
    phone: '+45',
    flag: '🇩🇰',
  },
  {
    languageCode: Intl.FI,
    code: 'FI',
    label: 'Finland',
    language: 'Finnish',
    phone: '+358',
    flag: '🇫🇮',
  },
  {
    languageCode: Intl.SE,
    code: 'SE',
    label: 'Sverige',
    language: 'Svenska',
    phone: '+46',
    flag: '🇸🇪',
  },
  {
    languageCode: Intl.DE,
    code: 'DE',
    label: 'Germany',
    language: 'Deutsch',
    phone: '+49',
    flag: '🇩🇪',
  },
  {
    languageCode: Intl.NN,
    code: 'NO',
    label: 'Norge',
    language: 'Norsk',
    phone: '+47',
    flag: '🇳🇴',
  },
  {
    languageCode: Intl.IT,
    code: 'IT',
    label: 'Italy',
    language: 'Italian',
    phone: '+39',
    flag: '🇮🇹',
  },
  {
    languageCode: Intl.ES,
    code: 'ES',
    label: 'Spain',
    language: 'Spanish',
    phone: '+34',
    flag: '🇪🇸',
  },
  {
    languageCode: Intl.FR,
    code: 'FR',
    label: 'France',
    language: 'French',
    phone: '+33',
    flag: '🇫🇷',
  },
  {
    languageCode: Intl.EN,
    code: 'EN',
    label: 'United Kingdom',
    language: 'English',
    phone: '+44',
    flag: '🇬🇧',
  },
  // luxembourg
  {
    languageCode: Intl.LU,
    code: 'LU',
    label: 'Luxembourg',
    phone: '+352',
    flag: '🇱🇺',
  },
  // netherlands
  {
    languageCode: Intl.NL,
    code: 'NL',
    label: 'Netherlands',
    phone: '+31',
    flag: '🇳🇱',
  },
  // ireland
  {
    languageCode: Intl.EN,
    code: 'IE',
    label: 'Ireland',
    phone: '+353',
    flag: '🇮🇪',
  },
  // litchenstein
  {
    languageCode: Intl.DE,
    code: 'LI',
    label: 'Liechtenstein',
    phone: '+423',
    flag: '🇱🇮',
  },
  // monaco (france)
  {
    languageCode: Intl.FR,
    code: 'MC',
    label: 'Monaco',
    phone: '+377',
    flag: '🇲🇨',
  },
  // lithuania
  {
    languageCode: Intl.EN,
    code: 'LT',
    label: 'Lithuania',
    phone: '+370',
    flag: '🇱🇹',
  },
  // malta
  {
    languageCode: Intl.EN,
    code: 'MT',
    label: 'Malta',
    phone: '+356',
    flag: '🇲🇹',
  },
  // scotland
  {
    languageCode: Intl.EN,
    code: 'SC',
    label: 'Scotland',
    phone: '+44',
    flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
  },
  // poland
  {
    languageCode: Intl.PL,
    code: 'PL',
    label: 'Poland',
    phone: '+48',
    flag: '🇵🇱',
  },
  // switzerland
  {
    languageCode: Intl.CH,
    code: 'CH',
    label: 'Switzerland',
    language: 'French',
    phone: '+41',
    flag: '🇨🇭',
  },
  {
    languageCode: Intl.US,
    code: 'US',
    label: 'United States',
    language: 'English',
    phone: '+1',
    flag: '🇺🇸',
  },
  {
    languageCode: Intl.CY,
    code: 'CY',
    label: 'Cyprus',
    language: 'Greek',
    phone: '+357',
    flag: '🇨🇾',
  },
  {
    languageCode: Intl.PT,
    code: 'PT',
    label: 'Portugal',
    language: 'Portugese',
    phone: '+351',
    flag: '🇵🇹',
  },
  {
    languageCode: Intl.ID,
    code: 'ID',
    label: 'Indonesia',
    language: 'Indonesian',
    phone: '+62',
    flag: '🇮🇩',
  },
  {
    languageCode: Intl.FO,
    code: 'FO',
    label: 'Faroe Islands',
    language: 'Faroese',
    phone: '+298',
    flag: '🇫🇴',
  },
  {
    languageCode: Intl.IS,
    code: 'IS',
    label: 'Iceland',
    language: 'Icelandic',
    phone: '+354',
    flag: '🇮🇸',
  },
  {
    languageCode: Intl.AU,
    code: 'AU',
    label: 'Australia',
    language: 'English',
    phone: '+61',
    flag: '🇦🇺',
  },
  {
    languageCode: Intl.BR,
    code: 'BR',
    label: 'Brazil',
    language: 'Portuguese',
    phone: '+55',
    flag: '🇧🇷',
  },
  // Austria
  {
    languageCode: Intl.AT,
    code: 'AT',
    label: 'Austria',
    language: 'German',
    phone: '+43',
    flag: '🇦🇹',
  },
  // belgium
  {
    languageCode: Intl.BE,
    code: 'BE',
    label: 'Belgium',
    phone: '+32',
    flag: '🇧🇪',
  },
].sort((a, b) => a.phone.localeCompare(b.phone));
